import { HamburgerProp } from './Header';

const Hamburger: React.FC<HamburgerProp> = ({ isOpen }) => {

  return (
    <>
    <div className="hamburger">
      <div className="burger burger1" />
      <div className="burger burger2" />
      <div className="burger burger3" />
    </div>

    <style>{`

      .hamburger {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 10;
      }
    
      .burger {
        width: 2rem;
        height: 0.25rem;
        border-radius: 10px;
        background-color: white;
        margin: 0.2rem;
        transform-origin: 1px;
        transition: all 0.3s linear;
      }
      .burger1 {
        transform: ${ isOpen ? 'rotate(45deg)' : 'rotate(0)'};
      }
      .burger2 {
        transform: ${ isOpen ? 'translateX(-100%)' : 'translateX(0)'};
        opacity: ${isOpen ? 0 : 1};
      }
      .burger3 {
        transform: ${ isOpen ? 'rotate(-45deg)' : 'rotate(0)'}
      }
      
      @media (max-width: 767px) {
      }
  `}</style>
    </>
  )
}


export default Hamburger;